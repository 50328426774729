import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { addAuthentication } from '../../../components/addAuthentication';
import './scss/Billing.scss';
import { DashboardPageHeading } from '../../../components/dashboard';
import { DashboardLayout } from '../../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amwell Private Practice | Dashboard - Plan & Payment</title>
    </Helmet>
    <DashboardLayout>
      <div className="BillingPage">
        <DashboardBillingPageHeading />
        <div className="BillingPage-container"></div>
      </div>
    </DashboardLayout>
  </>
));

const DashboardBillingPageHeading = () => (
  <DashboardPageHeading
    heading={'My Telehealth Practice Users'}
    links={[
      { label: 'Billing History', to: '/dashboard/billing' },
      {
        label: 'Saved Payment Information',
        to: '/dashboard/billing/information',
      },
    ]}
  />
);
